export const cleanseValue = (value) => {
  const cleanedValue = [value[0]]
  value.slice(1).forEach((node) => {
    if (
      checkNodeChildren(node.children.slice(1)).length ||
      node.id === 'keyImages'
    ) {
      cleanedValue.push(removeTrailingEmptyParagraphs(node));
    }
  });
  return cleanedValue;
};

const checkNodeChildren = (nodeChildren) => {
  const newNodeChildren = [];
  let checkedNodeChildren;
  nodeChildren.forEach((node) => {
    checkedNodeChildren = [];
    if ('children' in node) {
      checkedNodeChildren = checkNodeChildren(node.children);
      if (checkedNodeChildren.length) {
        newNodeChildren.push(
          Object.assign(Object.assign({}, node), {
            children: checkedNodeChildren,
          })
        );
      }
    } else {
      if (isTextNodeNotEmpty(node)) {
        newNodeChildren.push(Object.assign({}, node));
      }
    }
  });
  return newNodeChildren;
};

const isTextNodeNotEmpty = (node) => {
  return 'text' in node && !!node.text.replace(/\s+/g, ' ').trim();
};

const removeTrailingEmptyParagraphs = (node) => {
  if (node.children.length === 2) {
    return node;
  }
  let newNodeChildren = [];
  for (let i = node.children.length - 1; i >= 0; i--) {
    if (
      node.children[i].type !== 'paragraph' ||
      node.children[i].children.length > 1 ||
      isTextNodeNotEmpty(node.children[i].children[0])
    ) {
      newNodeChildren = node.children?.slice(0, i + 1);
      break;
    }
  }
  return Object.assign(Object.assign({}, node), { children: newNodeChildren });
};

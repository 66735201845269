import escapeHtml from 'escape-html';
import { Text } from 'slate';

export const convertJsonToHtml = async (node) => {
  if (Text.isText(node)) {
    let styledText = node.text;
    if (node.bold) {
      styledText = `<b>${styledText}</b>`;
    }
    if (node.italic) {
      styledText = `<i>${styledText}</i>`;
    }
    if (node.underline) {
      styledText = `<u>${styledText}</u>`;
    }
    if (styledText !== node.text) {
      return styledText;
    }
    return escapeHtml(node.text);
  }

  const promises = [];
  for (const child in node.children) {
    const promise = await convertJsonToHtml(node.children[child]);
    promises.push(promise);
  }
  const childrenArray = await Promise.all(promises);
  const children = childrenArray.join('');

  // const children = node.children.map(async (n) => await reportService.convertReportJSONToHTML(n)).join('');
  switch (node.type) {
    case 'section': {
      return `<div class="section" id="${node.id}">${children}</div>`;
    }
    case 'reportTitle': {
      return `<p class="report-title">${children}</p>`;
    }
    case 'reportSubTitle': {
      return `<p class="report-subtitle">${children}</p>`;
    }
    case 'sectionTitle': {
      return `<p class="section-title">${children}</p>`;
    }
    case 'list': {
      return `<ul class="list">${children}</ul>`;
    }
    case 'listTitle': {
      return `<p class="list-title">${children}</p>`;
    }
    case 'listItem': {
      return `<li>
                    <p class="list-item">
                        ${children}
                    </p>
                </li>`;
    }
    case 'keyImagesGrid': {
      return `<div class="key-images-grid">${children}</div>`;
    }
    case 'keyImage': {
      // let imageUrl = await reportService.getSignedUrl('5c-captured-images', node.id);
      return `<div class="key-images-container">
                    <img
                        class="key-image"
                        alt="key-image"
                        src='${node.url}'
                        height='256'
                        width='256'
                    />
                    <p class="key-image-caption">${children}</p>
                </div>`;
    }
    case 'table': {
      return `<table class="table">${children}</table>`;
    }
    case 'tableBody': {
      return `<tbody class="table-body">${children}</tbody>`;
    }
    case 'p': {
      return `<p class="section-paragraph">${children}</p>`;
    }
    case 'tr': {
      return `<tr  class="table-row">${children}</tr>`;
    }
    case 'td': {
      let attributeString = '';
      if (node.attributes) {
        for (const [attribute, value] of Object.entries(node.attributes)) {
          attributeString += `${attribute}="${value}"`;
        }
      }
      return `<td ${attributeString}>
                    <p class="table-cell">${children}</p>
                 </td>`;
    }
    case 'ul': {
      return `<ul class="list">${children}</ul>`;
    }
    case 'ol': {
      return `<ol class="list">${children}</ol>`;
    }
    case 'li': {
      return `<li>
                    <p class="list-item">
                        ${children}
                    </p>
                </li>`;
    }
    case 'lic': {
      return `${children}`;
    }
    case 'align_center': {
      return `<p style="text-align: center"> ${children}<p>`;
    }
    default:
      return children;
  }
};
